<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
import draggable from 'vuedraggable'

export default {
  name: "BoProduct",
  extends: Base,
  components: {
    draggable
  },
  data(){
    return {
    Name:"BoProduct",
    mrProdCat: {},
    category: {},
    statusProduct: {},
    row:{category:'',statusProduct:''},
    mpTitleLength:0
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
    this.refreshData()
  },
  methods: {
    endDrag() {
      BOGen.apirest('/' + this.Name, { data: this.data.data, type: 'sort' }, (err, resp) => {
        console.log(resp)
      }, "POST");
    },
    addRow(){
       this.row.mp_variant.push({variant:'',mp_sub_variant:[{}]});
    },
    sort(){
      this.filter.sort = this.filter.sort == 'all' ? '' : 'all'
      if(this.filter.sort == 'all'){
        this.filter.category=''
        this.filter.statusProduct=''
      }
      var query = this.filter
      delete query.page
      this.$router.push({name:this.Name, query: query})
    }
  },
  watch:{
    '$route.query'(){
      if(this.$route.params.id){
        this.$set(this.row, 'category', this)
        this.$set(this.row, 'statusProduct', this)
        this.$set(this.row, 'search', this)
        this.$set(this.row, 'page', this)
        setTimeout(()=>{
          this.row.category = this.$route.query.category
          this.row.statusProduct = this.$route.query.statusProduct
          this.row.search = this.$route.query.search
          this.row.page = this.$route.query.page
        },500)
      }
      this.refreshData()
    },
    'filter.category'(v){
      if(!v) return
        this.search()
    },
    'filter.statusProduct'(v){
      if(!v) return
        this.search()
    },
    'row.mp_title'(v) {
        if (!v) return
        this.mpTitleLength = v.length
    },
  }  
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <!-- ======================================================================== 
      LIST DATA
    ============================================================================= -->
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
             <VForm @resp="search">
              <div class="row">
                <div class="col-sm-2">
                  <h5 class="card-title">{{ObjectName}} List</h5>
                </div>
                <div class="col-sm-2">
                    <button class="btn btn-success" type="button" @click="sort()">
                      <i class="fas fa-sort"></i> Sorting All
                    </button>
                </div>
                <div class="col-sm-2">
                  <select2 :options="category" :object="['mpc_id','mpc_name']" v-model="filter.category">
                    <option value="">-- Select Category --</option>
                  </select2>
                </div>
                <div class="col-sm-2">
                  <select2 :options="statusProduct" v-model="filter.statusProduct">
                    <option value="">-- Select Status --</option>
                  </select2>
                </div>
                <div class="col-sm-3">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input type="text" v-model="filter.search" v-on:keyup.enter="search"  class="form-control" placeholder="Search...">
                        <div class="input-group-append">
                          <button class="btn btn-info" type="button" @click="search()">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                  </div>
                </div>
              </VForm>
          </div>

          <div class="card-body">
          <div class="alert alert-info">
            Image Requirements for Products :
            <ul class="mb-0 mt-2">
              <li><strong>Dimension: </strong><span>400x400px</span></li>
              <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
              <li><strong>Max. Size: </strong><span>2 MB</span></li>
            </ul>
          </div>
            <draggable v-model="data.data" tag="div" class="row" @end="endDrag">
                <div class="col-md-3 mb-3" v-for="(v,k) in data.data" :key="k">
                  <div class="item-list collection" >
                    <div class="row text-center">
                      <div class="col-md-12">
                        <div class="product-img recipe-cat">
                          <img :src="uploader(v.mp_image,'200')" alt="prod">
                          <div class="pro-img-overlay">
                            <router-link class="bg-info" :to="{name:Name,params:{id:v.id},query:$route.query}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
                            <a href="javascript:;" class="bg-danger" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                          </div>
                          <span class="label label-success" v-if="v.mp_is_active=='Y'">Active</span>
                          <span class="label label-danger" v-else>Inactive</span>
                        </div>                        
                        <h5 class="card-title mt-3">{{v.mp_title}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
              <div v-if="NotFound" class="text-center col-md-12">
                <h5 class="tc">{{NotFound}}</h5>
              </div>
              <div v-if="data.data===false"  class="text-center col-md-12">
                <LoadingSpinner light></LoadingSpinner>
              </div>
              <div class="row">
                  <div class="col-12 justify-content-end">
                    <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        <!-- ======================================================================== 
      FORM CRUD 
    ============================================================================= -->
        <div class="card" v-if="$route.params.id">
          <VForm @resp="submitForm" method="post">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-sm-8">
                  <BoField name="mp_title" v-model="row.mp_title"></BoField>
                  <small class="mb-2 d-inline-block text-info">{{validation('mp_title').maxlength-mpTitleLength}} character remaining </small>
                  <BoField name="mp_cat">
                    <select2 name="mp_cat" v-bind="validation('mp_cat')" :options="mrProdCat" :object="['mpc_id','mpc_name']" v-model="row.mp_cat">
                      <option value="">-- Select Category --</option>
                    </select2>
                  </BoField>
                  <BoField name="mp_desc" mandatory>
                  <CKEditor name="mp_desc" v-model="row.mp_desc" v-bind="validation('mp_desc')"></CKEditor>
                  </BoField>
                  <BoField name="mp_link_shop" v-model="row.mp_link_shop"></BoField>
                  <BoField name="mp_tags" mandatory>
                    <TagsInput name="mp_tags" v-model="row.mp_tags"
                  :attr="validation('mp_tags')"></TagsInput>
                  </BoField>
                  <!-- =================  -->
                  <div class="py-3 px-3 border mb-3">
                    <div class="row mb-3">
                      <div class="col-8">
                        <h5 class="card-title mb-2">Variants</h5>
                        <div class="variants" v-for="(vr,k) in row.mp_variant" :key="k">
                          <div class="row">
                            <div class="col-8">
                              <BoField :attr="{placeholder:'Ex: Sachet'}" v-model="vr.variant"></BoField>
                            </div>
                            <div class="col-4 text-left" style="margin-top: 22px;">
                              <a @click="addRow()" href="javascript:;" class="btn btn-info mr-1"
                                v-tooltip="'Add Variant'"><i class="ti-plus"></i></a>
                              <a v-if="row.mp_variant.length>1" @click="row.mp_variant.splice(k,1)" href="javascript:;"
                                class="btn btn-danger" v-tooltip="'Delete Variant'"><i class="ti-trash"></i></a>
                            </div>
                          </div>
                          <div class="row" v-for="(vs,ks) in vr.mp_sub_variant" :key="ks">
                            <div class="col-md-6">
                              <BoField :label="'value'" :attr="{placeholder:'Ex: 25'}" v-model="vs.field_value">
                              </BoField>
                            </div>
                            <div class="col-md-4 text-left" style="margin-top: 22px;">
                              <a @click="vr.mp_sub_variant.push({field_value:''})" href="javascript:;"
                                class="btn btn-success mr-1" v-tooltip="'Add Value'"><i class="ti-plus"></i></a>
                              <a v-if="vr.mp_sub_variant.length>1" @click="vr.mp_sub_variant.splice(ks,1)"
                                href="javascript:;" class="btn btn-warning" v-tooltip="'Delete Value'"><i
                                  class="ti-trash"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- =================  -->
                  <div class="row mb-3">
                    <div class="col-md-8">
                      <h5 class="card-title">Meta Content</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <BoField name="mp_seo_title" :attr="{minlength:'3'}" v-model="row.mp_seo_title" mandatory></BoField>
                      <BoField name="mp_seo_keyword" mandatory>
                        <TagsInput name="mp_seo_keyword" v-model="row.mp_seo_keyword"
                        :attr="validation('mp_seo_keyword')"></TagsInput>
                      </BoField>
                    </div>
                    <div class="col-sm-12">
                      <BoField name="mp_seo_desc" mandatory>
                        <textarea name="mp_seo_desc" rows="5"  :attr="{minlength:'10'}" v-model="row.mp_seo_desc" v-bind="validation('mp_seo_desc')"
                        class="form-control"></textarea>
                      </BoField>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-7">
                      <BoField name="mp_is_active">
                        <div class="row">
                          <radio name="mp_is_active" v-model="row.mp_is_active" option="Y" :attr="validation('mp_is_active')">Active</radio>
                          <radio name="mp_is_active" v-model="row.mp_is_active" option="N">Inactive</radio>
                        </div>
                      </BoField>   
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <BoField name="mp_image">
                    <Uploader name="mp_image" type="product" uploadType="cropping" :param="{thumbnail:true}" v-model="row.mp_image"></Uploader>
                  </BoField>
                  <BoField name="mp_nutrition_fact">
                    <Uploader name="mp_nutrition_fact" type="nutrition" uploadType="upload" :param="{thumbnail:true}" v-model="row.mp_nutrition_fact"></Uploader>
                  </BoField>
                </div>
                <div class="col-sm-12">
                  <div class="text-right">
                    <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form 
                      <i class="icon-arrow-right14 position-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </template>
